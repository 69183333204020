import { AxiosError } from 'axios';

import { useMutation } from 'react-query';

import { SOFT_CREDIT_AUTHORIZATION_PATH } from '@hl-portals/constants';

import { enhancedAxios, handleErrors } from '@hl-portals/helpers';

export type SoftCreditCheckParams = {
  lead_id: string;
  authorize: boolean;
  first_name: string;
  last_name: string;
  property_address: string;
  property_city: string;
  property_state: string;
  property_zip: string;
};

export const useSoftCreditCheck = () =>
  useMutation({
    mutationFn: (params: SoftCreditCheckParams) =>
      enhancedAxios({
        url: SOFT_CREDIT_AUTHORIZATION_PATH,
        method: 'POST',
        data: params,
      }),
    onError: (...args) => {
      handleErrors(args[0] as AxiosError);
    },
  });
