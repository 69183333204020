import { formatAddress } from '@hl-portals/helpers';

import { SoftCreditCheckParams } from '@hl-portals/hooks';

type ClientInfo = {
  name: string;
  email: string;
  phone: string;
};
type SecondaryClientInfo = {
  name?: string;
  email?: string;
  phone?: string;
};

type CreateSoftCreditCheckParams = {
  lead_id: number;
  departing_property_full_address: string;
  client: ClientInfo;
  additional_client: SecondaryClientInfo;
};

type ClientCreditCheckPayload = {
  primaryClient: SoftCreditCheckParams;
  secondaryClient?: SoftCreditCheckParams;
};

export const createSoftCreditCheckParams = ({
  lead_id,
  departing_property_full_address,
  client,
  additional_client,
}: CreateSoftCreditCheckParams): ClientCreditCheckPayload => {
  const { city, zipCode, fullAddress, state } = formatAddress(
    departing_property_full_address
  );

  const clientName = client.name.split(' ');
  const [primaryClientFirstName, primaryClientLastName] = [
    clientName[0],
    clientName[clientName.length - 1],
  ];

  const primaryClient = {
    lead_id: `${lead_id}`,
    authorize: true,
    first_name: primaryClientFirstName,
    last_name: primaryClientLastName,
    property_address: fullAddress,
    property_city: city,
    property_state: state,
    property_zip: zipCode,
  } as SoftCreditCheckParams;

  let secondaryClient = {} as SoftCreditCheckParams;

  if (additional_client?.name) {
    const additionalClientName = additional_client.name.split(' ');
    const [firstName, lastName] = [
      additionalClientName[0],
      additionalClientName[additionalClientName.length - 1],
    ];
    secondaryClient = JSON.parse(JSON.stringify(primaryClient));
    secondaryClient.first_name = firstName;
    secondaryClient.last_name = lastName;
  }

  return { primaryClient, secondaryClient };
};
