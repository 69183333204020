/*
  This function determines whether a user is logging in for the first time in over a day
    by comparing the stored lastLoggedIn timestamp with the current timestamp.

  nextauth.message.timestamp refers to the time at which the user's session was last updated or created.
*/
export const useNewDailyActiveUser = (): { isNewActiveDailyUser: boolean } => {
  const lastLoggedIn =
    JSON.parse(localStorage.getItem('nextauth.message') || '')?.timestamp || 0;
  const currentTimestamp = Math.floor(new Date().getTime() / 1000);
  const dailySeconds = 86400;
  const isNewActiveDailyUser =
    lastLoggedIn && lastLoggedIn + dailySeconds < currentTimestamp;

  return { isNewActiveDailyUser };
};
