import addresser from 'addresser';

import { capitalizeWords } from '@hl-portals/helpers';

type FormattedAddress = {
  fullAddress: string;
  firstLine: string;
  secondLine: string;
  city: string;
  state: string;
  zipCode: string;
};

export const formatAddress = (address?: string): FormattedAddress => {
  if (!address) {
    return {
      fullAddress: '',
      firstLine: '',
      secondLine: '',
      city: '',
      state: '',
      zipCode: '',
    };
  }

  const {
    addressLine1,
    placeName: city,
    stateAbbreviation,
    zipCode,
  } = addresser.parseAddress(address?.toLowerCase() || '');

  const fullAddress = `${capitalizeWords(addressLine1)}, ${capitalizeWords(
    city
  )} ${stateAbbreviation} ${zipCode}`;

  const firstLine = `${capitalizeWords(addressLine1)}`;
  const secondLine = `${capitalizeWords(
    city
  )}, ${stateAbbreviation} ${zipCode}`;

  return {
    firstLine,
    secondLine,
    fullAddress,
    city,
    state: stateAbbreviation,
    zipCode,
  };
};
