import { usePublicEvent, useScreenSize } from '@hl-portals/hooks';

type Device = 'mobile_web' | 'desktop_web';

type CoreEventData = {
  device: Device;
};

export const LO_PORTAL_EVENTS = {
  ADDED_REAL_ESTATE_AGENT_CONTACT_INFO: 'added_real_estate_contact_info',
  ADDED_ASSISTANT_PROCESSOR_CONTACT_INFO:
    'added_assistant_processor_contact_info',
  CLICKED_NAV_BAR_EXPANDED: 'clicked_nav_bar_expanded',
  CLICKED_NAV_BAR_COLLAPSED: 'clicked_nav_bar_collapsed',
  CLICKED_PIPELINE_COLUMN_HEADER: 'clicked_pipeline_column_header',
  CLICKED_PIPELINE_ROW: 'clicked_pipeline_row',
  CLICKED_HELP_CENTER: 'clicked_help_center',
  CLICKED_RESOURCE_CENTER: 'clicked_resource_center',
  CLICKED_CLIENT_SUBMISSION: 'client_submit_button',
  CLICKED_SHARED_TASK_BUTTON: 'clicked_shared_task_button',
  CLICKED_SHARE_TASK_LINK: 'clicked_share_task_link',
  CLICKED_PORTAL_VIEW_SEE_ALL_STAGES: 'clicked_portal_view_see_all_stages',
  CLICKED_TRANSACTION_VIEW_SEE_ALL_STAGES:
    'clicked_transaction_view_see_all_stages',
  COMPLETED_PHOTO_UPLOAD_TASK: 'completed_photo_upload_task',
  COMPLETED_SCHEDULE_NOTARY_TASK: 'completed_schedule_notary_task',
  EMAIL_LRM: 'email_lender_relationship_manager_call',
  SCHEDULED_LRM_CALL: 'scheduled_lender_relationship_manager_call',
  SEARCHED_TRANSACTION: 'searched_transaction',
  DAILY_ACTIVE_USER: 'daily_active_user',
  CAPTURE_REFERRER: 'capture_referrer',
};

export type LoanOfficerPortalEvent = {
  event_action: string;
  event_payload?: Partial<CoreEventData> & {
    [key: string]: string | number | boolean | Array<any> | Record<any, any>;
  };
};

export function useLoanOfficerPortalEvent() {
  const { recordPublicEvent } = usePublicEvent();
  const { isMobile } = useScreenSize();

  function logLoanOfficerPortalEvent({
    event_action,
    event_payload,
  }: LoanOfficerPortalEvent) {
    recordPublicEvent({
      source: 'lo_portal',
      event_action,
      event_payload: {
        device: isMobile ? 'mobile_web' : 'desktop_web',
        ...event_payload,
      },
    });
  }

  return { logLoanOfficerPortalEvent };
}
