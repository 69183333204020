export type HotjarFn = (
  action: 'identify',
  userId: number,
  attributes: Record<string, any>
) => void;

declare global {
  interface Window {
    hj: HotjarFn;
  }
}

export const useHotjarAttributes = <Payload,>() => {
  const record = (payload: Payload & { UserID: number }) => {
    if (typeof window !== 'undefined' && typeof window.hj === 'function') {
      window.hj('identify', payload.UserID, payload);
    } else {
      console.warn('Hotjar is not available.');
    }
  };

  return { record };
};
